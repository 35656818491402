import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import logo from "./assets/Vibe-Stream-Logo.png";
import nxtIcn from "./assets/next-button.png";
import preIcn from "./assets/previous-button.png";
import playIcn from "./assets/play-button.png";
import unmteIcn from "./assets/unmute-icon.png";
import vwAllIcn from "./assets/view-all-button.png";
import homBtn from "./assets/button-home-filled-outline.png";
import lkIcn from "./assets/link-icon.png";
import lkIcnBlk from "./assets/link-icon-blk.png";
import moreBtn from "./assets/More-button.png";
import videojs from "video.js";
import "videojs-playlist";
import "videojs-contrib-ads";
import "videojs-ima";
import "video.js/dist/video-js.css";
import "videojs-ima/dist/videojs.ima.css";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import {
  getSingleVideo,
  getPlaylist,
  getFeaturedList,
  getWebCategories,
  getFeaturedPlaylistVideos,
} from "./service/featured";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import duration from "dayjs/plugin/duration";
import customParseFormat from "dayjs/plugin/customParseFormat";
import useVideoAnalytics from "./hooks/useVideoAnalytics";
import { copyToClipboard, logSkipVideo } from "./helpers/globals";
import { Link } from "react-router-dom";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(duration);
dayjs.extend(customParseFormat);

// const REFRESH_PAGE_DURATION = 22 * 60 * 1000;

const App = () => {
  const videoRef = useRef(null);
  const [videos, setVideos] = useState([]);
  const [curTitle, setCurTitle] = useState("");
  const [curArtist, setcurArtist] = useState("");
  const [playlistVideos, setPlaylist] = useState("");
  const [currentPlayingVideo, setCurrentPlayingVideo] = useState(null);
  const [isVideoFnd, setIsVideoFnd] = useState(true);
  const [modal, setModal] = useState(false);
  const [copiedTitle, setCopiedTitle] = useState("");
  const [featuredVideos, setFeaturedVideos] = useState([]);
  const [currentFeaturedVideo, setCurrentFeaturedVideo] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [header, setHeader] = useState("");
  const [videoUUID, setVideoUUID] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [heightPlr, setHeightPle] = useState("");
  const [heightDiv, setHeightDiv] = useState("");
  const [isSticky, setIsSticky] = useState(false);
  const [currentPlaylist, setCurrentPlaylist] = useState(null);
  const [nextPlaylist, setNextPlaylist] = useState(null);
  const [showMaxSkipWarning, setShowMaxSkipWarning] = useState(false);

  const analytics = useVideoAnalytics();

  useEffect(() => {
    if (!currentPlaylist) {
      const playlistTime = dayjs().format("HH:00:00");

      getFeaturedPlaylistVideos(playlistTime)
        .then((response) => {
          const { data } = response.data;
          setCurrentPlaylist({
            time: playlistTime,
            playlist: {
              ...data,
              videos: data.videos.sort((a, b) => a.order - b.order),
            },
          });
        })
        .catch((error) => {
          console.error("Error while fetching the playlist:", error);
          setCurrentPlaylist(null);
        });
    }
  }, []);

  useEffect(() => {
    let nextPlaylistInterval;
    if (currentPlaylist) {
      nextPlaylistInterval = setInterval(() => {
        const playlistTime = dayjs().add(2, "minutes").format("HH:00:00");

        if (currentPlaylist?.time !== playlistTime) {
          clearInterval(nextPlaylistInterval);
          getFeaturedPlaylistVideos(playlistTime).then((response) => {
            const { data } = response.data;
            setNextPlaylist({
              time: playlistTime,
              playlist: {
                ...data,
                videos: data.videos.sort((a, b) => a.order - b.order),
              },
            });
          }).catch((error) => console.error("Error while fetching the next playlist:", error));
        }
      }, 50);
    }

    return () => clearInterval(nextPlaylistInterval);
  }, [currentPlaylist]);

  useEffect(() => {
    let playlistSwapInterval;
    if (currentPlaylist && nextPlaylist) {
      playlistSwapInterval = setInterval(() => {
        const playlistTime = dayjs().format("HH:00:00");
        if (nextPlaylist?.time === playlistTime) {
          clearInterval(playlistSwapInterval);
          // player.current?.dispose?.();
          setNextPlaylist(null);
          setCurrentPlaylist(nextPlaylist);
        }
      }, 50);
    }

    return () => clearInterval(playlistSwapInterval);
  }, [currentPlaylist, nextPlaylist]);

  useEffect(() => {
    try {
      if (currentPlaylist && videoRef.current) {
        const playlistVideos = currentPlaylist.playlist.videos;
        
        const urls = playlistVideos.map((video) => {
          return {
            sources: [
              { src: video.video_url, type: "application/x-mpegURL" },
            ],
            poster: video.thumbnail_url,
          };
        });
        
        if (player.current) {
          player.current.playlist(urls);

          player.current.on("play", () => {
            let curInd = player.current.playlist.currentItem();
            const playingVideo = playlistVideos[curInd];
            analytics.start(playingVideo.id);
            setCurTitle(playingVideo.title);
            setcurArtist(playingVideo.artist);
            setVideoUUID(playingVideo.video_id);
            setCurrentPlayingVideo(playingVideo);
          });

          handleChangeVideo(playlistVideos[0].id, true);
        } else {
          const videoMeta = playlistVideos[0];
          setCurTitle(videoMeta.title);
          setcurArtist(videoMeta.artist);
          setVideoUUID(videoMeta.video_id);
          setThumbnail(videoMeta.thumbnail_url);
          
          // Start
          const imaOptions = {
            adTagUrl:
              "https://pubads.g.doubleclick.net/gampad/ads?iu=/22978710279/halfpage-for-video&description_url=[placeholder]&tfcd=0&npa=0&sz=640x480&ciu_szs=300x600&gdfp_req=1&output=vast&env=vp&unviewed_position_start=1&impl=s&correlator=",
          };

          const videoOp = {
            controls: true,
            autoplay: "any",
            muted: true,
            nativeControlsForTouch: false,
            controlBar: {
              playToggle: false,
              progressControl: false,
              pictureInPictureToggle: false,
            },
          };

          player.current = videojs(videoRef.current, videoOp, function () {
            if (this) {
              this.ima(imaOptions);
              this.playlist(urls);
              this.playlist.autoadvance(0);

              this.on("play", () => {
                let curInd = player.current.playlist.currentItem();
                const playingVideo = playlistVideos[curInd];
                analytics.start(playingVideo.id);
                setCurTitle(playingVideo.title);
                setcurArtist(playingVideo.artist);
                setVideoUUID(playingVideo.video_id);
                setCurrentPlayingVideo(playingVideo);
              });

              this.on("ended", () => {
                analytics.record();
                this.ima.requestAds();
              });

              this.on("pause", function () {
                analytics.pause();
              });

              this.playlist.repeat(true);

              this.on("touchstart", (e) => {
                if (e.target.classList.contains("vjs-playing")) {
                  // this.bigPlayButton.show();
                  analytics.pause();
                  this.pause();
                }
              });
            }
          });
        }

        // return () => player.current?.dispose();
      }
    } catch (error) {
      console.error("while initialize", error);
    }

    // return () => {
    //   player.current?.dispose?.();
    // }
  }, [currentPlaylist]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, REFRESH_PAGE_DURATION);
  // }, []);

  const toggle = () => setModal(!modal);

  const changeTitle = (title) => {
    setModal(!modal);
    setCopiedTitle(title);
  };

  const player = useRef(null);

  const toHHMMSS = (duration) => {
    let mm = parseInt(duration / 60);
    duration = duration % 60;
    let ss = parseInt(duration);
    if (mm < 10) {
      mm = `0${mm}`;
    }
    if (ss < 10) {
      ss = `0${ss}`;
    }
    return `${mm}:${ss}`;
  };

  const handleChangeVideo = (id, skipLog = false) => {
    if (currentPlaylist && player.current) {
      const index = currentPlaylist.playlist.videos.findIndex((video) => {
        return video.id === id;
      });
      if (skipLog ||
        currentPlaylist.playlist.videos[
          player.current.playlist.currentItem()
        ]?.id === id ||
        logSkipVideo(id)
      ) {
        player.current.pause();
        analytics.record();
        player.current.playlist.currentItem(index);
        player.current.ima.requestAds();
        player.current.play();
      } else {
        setShowMaxSkipWarning(true);
      }
    }
  };

  const changeVideo = (id, e) => {
    setFeaturedVideos([]);

    const records = playlistVideos;
    for (let i = 0; i < records.length; i++) {
      records[i].is_playing = false;
    }
    setPlaylist([...records]);
    const urls = [];
    records.forEach((record) => {
      urls.push({
        sources: [
          { src: record.video_url, type: "application/x-mpegURL" },
        ],
        poster: record.thumbnail_url
      });
    });

    player.current.playlist(urls);
    player.current.playlist.autoadvance(0);
    player.current.on("play", () => {
      let curInd = player.current.playlist.currentItem();
      // analytics.start(records[curInd].id);
      setCurTitle(records[curInd].title);
      setcurArtist(records[curInd].artist);
      setVideoUUID(records[curInd].video_id);
      for (let i = 0; i < records.length; i++) {
        records[i].is_playing = false;
      }
      records[curInd].is_playing = true;
      setPlaylist([...records]);
    });
    player.current.on("touchstart", (e) => {
      if (e.target.classList.contains("vjs-playing")) {
        // this.bigPlayButton.show();
        // analytics.pause();
        player.current.pause();
      } else {
        player.current.play();
      }
    });


    const index = records.findIndex((video) => {
      return video.id === id;
    });
    // analytics.record();
    player.current.play();
    player.current.playlist.currentItem(index);
    for (let i = 0; i < records.length; i++) {
      records[i].is_playing = false;
    }
    records[index].is_playing = true;
    setVideos(records);
  };

  const unmuteVideo = (e) => {
    player.current.muted(false);
    e.target.style = "visibility: hidden;";
  };

  let IsNoVideo = ({ children }) => {
    return !isVideoFnd ? children : <></>;
  };

  useEffect(() => {
    let featureVideoInterval = null;
    if (featuredVideos.length) {
      featureVideoInterval = setInterval(() => {
        const currentStreamingFeaturedVideo = featuredVideos.find(
          (featuredVideo) => {
            if (!featuredVideo.streaming_time) {
              return true;
            }

            const streamingStartTime = dayjs(featuredVideo.streaming_time);
            const streamingEndTime = streamingStartTime.add(
              featuredVideo.video?.duration || 0,
              "seconds"
            );
            return (
              dayjs().isSameOrAfter(streamingStartTime) &&
              dayjs().isSameOrBefore(streamingEndTime)
            );
          }
        );

        if (currentFeaturedVideo?.id !== currentStreamingFeaturedVideo?.id) {
          setCurrentFeaturedVideo(currentStreamingFeaturedVideo || null);
        }
      }, 1000);
    }

    return () => clearInterval(featureVideoInterval);
  }, [featuredVideos, currentFeaturedVideo]);

  function skipToUserTimeOfVideo(streamingTime, videoMeta, playerObj) {
    // analytics.record();
    // analytics.start(videoMeta.id);
    if (streamingTime && videoMeta.duration) {
      const currentStreamingSeconds = dayjs().diff(
        dayjs(currentFeaturedVideo.streaming_time),
        "s"
      );
      if (currentStreamingSeconds <= videoMeta.duration) {
        playerObj?.currentTime(currentStreamingSeconds);
      }
    }
  }

  useEffect(() => {
    try {
      if (currentFeaturedVideo?.video && videoRef.current) {
        const videoMeta = currentFeaturedVideo?.video;
        setCurTitle(videoMeta.title);
        setcurArtist(videoMeta.artist);
        setVideoUUID(videoMeta.video_id);
        setThumbnail(videoMeta.thumbnail_url);

        // Start
        const imaOptions = {
          adTagUrl:
            "https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/22978710279/jp-test-1&ciu_szs=300x250&env=vp&impl=s&gdfp_req=1&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=[description_url]&correlator=[timestamp]",
          // disableCustomPlaybackForIOS10Plus: true,
        };

        const urls = [
          {
            sources: [
              { src: videoMeta.video_url, type: "application/x-mpegURL" },
            ],
            poster: videoMeta.thumbnail_url
          },
        ];

        const videoOp = {
          controls: true,
          nativeControlsForTouch: false,
          // poster: thumbnail,
          controlBar: {
            playToggle: false,
            progressControl: false,
            pictureInPictureToggle: false,
          },
        };

        // analytics.record();
        if (player.current?.src) {
          player.current.on("play", () => {
            skipToUserTimeOfVideo(
              currentFeaturedVideo.streaming_time,
              videoMeta,
              player.current
            );
          });
          // player.on('pause', () => {
          //   player.posterImage.show(); 
          // });
          player.current.src(urls[0].sources);
          player.current.on("ads-manager", function (response) {
            const adsManager = response.adsManager;
            if (
              adsManager &&
              window.google?.ima?.AdEvent?.Type?.ALL_ADS_COMPLETED
            ) {
              adsManager.addEventListener(
                window.google?.ima.AdEvent.Type.ALL_ADS_COMPLETED,
                () => {
                  skipToUserTimeOfVideo(
                    currentFeaturedVideo.streaming_time,
                    videoMeta,
                    player.current
                  );
                }
              );
            }
          });
        } else {
          player.current = videojs(
            videoRef.current,
            videoOp,
            function (...rest) {
              if (this) {
                this.ima(imaOptions);
                this.playlist(urls);
                this.playlist.autoadvance(0);

                this.on("play", () => {
                  skipToUserTimeOfVideo(
                    currentFeaturedVideo.streaming_time,
                    videoMeta,
                    this
                  );
                });

                this.on("ads-manager", function (response) {
                  const adsManager = response.adsManager;
                  if (
                    adsManager &&
                    window.google?.ima?.AdEvent?.Type?.ALL_ADS_COMPLETED
                  ) {
                    adsManager.addEventListener(
                      window.google?.ima.AdEvent.Type.ALL_ADS_COMPLETED,
                      () => {
                        skipToUserTimeOfVideo(
                          currentFeaturedVideo.streaming_time,
                          videoMeta,
                          player.current
                        );
                      }
                    );
                  }
                });

                this.on("pause", function () {
                  if (
                    !videoMeta.duration &&
                    this.currentTime() < videoMeta.duration
                  ) {
                    this.play();
                  } else {
                    // analytics.pause();
                  }
                });
              }
            }
          );
        }
      }
    } catch (error) {
      console.error("while autoplay", error);
    }
  }, [currentFeaturedVideo, videoRef.current]);

  useEffect(() => {
    const googletag = window.googletag;
    googletag.cmd.push(function () {
      googletag.display("div-gpt-ad-1699300221224-0");
    });

    getFeaturedList()
      .then((response) => {
        const retrievedFeaturedVideos = response.data?.data;
        setFeaturedVideos(
          retrievedFeaturedVideos?.length ? retrievedFeaturedVideos : []
        );
        setHeader(response.data.web_headers[0].HomePageHeaderText);
      })
      .catch((error) => {
        console.error("Error while fetching the featured videos", error);
        setFeaturedVideos([]);
      });

    // getPlaylist(115)
    //   .then((response) => {
    //     const retrievedPlaylistVideos = response.data?.data.videos;
    //     retrievedPlaylistVideos?.sort((a, b) =>
    //       a.order > b.order ? 1 : b.order > a.order ? -1 : 0
    //     );
    //     setPlaylist(
    //       retrievedPlaylistVideos?.length ? retrievedPlaylistVideos : []
    //     );
    //   })
    //   .catch((error) => {
    //     console.error("Error while fetching the playlist videos", error);
    //     setPlaylist([]);
    //   });
    
    // getWebCategories()
    //   .then((response) => {
    //     const retrievedCategories = response.data?.data;
    //     if (retrievedCategories?.length) {
    //       setActiveCategory(retrievedCategories[0]);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error while fetching the category playlists", error);
    //     setActiveCategory(null);
    //   });

    const handleScroll = () => {
      setIsSticky(window.scrollY > 15);
    };

    window.addEventListener('scroll', handleScroll);

    if (window.screen.width > 800) {
      setHeightPle(document.getElementById("featured-player").offsetHeight + "px");
      setHeightDiv(`${window.screen.height}px`);
    } else {
      setHeightPle("auto")
      setHeightDiv("auto")
    }
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div style={{ backgroundColor: "black", height: heightDiv }}>
      <div style={{ width: "100%" }}>
        <div className="img-con-header">
          <div className='link-con'>
            <div style={{
              textAlign: "left",
              color: "white",
              margin: "auto 0 auto",
            }}></div>
            <div style={{
              textAlign: "center",
              margin: "0 auto",
            }}>
              <Link to="/">
                <img
                  className="imgLogo"
                  src={logo}
                  width="41%"
                  alt="logo"
                />
              </Link>
            </div>
            <div
              style={{
                textAlign: "right",
                margin: "auto 0",
              }}
            >
              <Link to="/allplaylists">
                <img className="imgLogo" src={moreBtn} width="39%" alt="home" />
              </Link>
            </div>
          </div>
        </div>
        <IsNoVideo>
          <div
            style={{ color: "white", margin: "4px auto", textAlign: "center" }}
          >
            <h2>
              Dang it, something went wrong. Please go to{" "}
              <Link to="/">VIBE-STREAM.TV</Link>
            </h2>
          </div>
        </IsNoVideo>
      </div>
      {window.screen.width > 800 ? (
        <div style={{
          width: "98%",
          color: "white",
          margin: "2px auto",
        }}>
          <Link to={`/video/${videoUUID}`}>
            <h4 className="font-semibold">{curTitle}</h4>
          </Link>
          <h6>{curArtist}</h6>
        </div>
      ) : (
        <></>
      )}
      <div className="video-con">
        <div className="player-con">
          <div className={`v ${isSticky ? 'vjs-fixed-box' : ''}`}>
            {(window.screen.width < 800) ? (
              
          <div style={{
            color: "white",
            margin: "2px auto",
          }}>
            <Link to={`/video/${videoUUID}`}>
              <h4 className="font-semibold">{curTitle}</h4>
            </Link>
            <h6>{curArtist}</h6>
          </div>
            ) : (
                <></>
            )}
            <video
              id='featured-player'
              ref={videoRef}
              className="video-js vjs-16-9 vjs-big-play-centered"
              playsInline
            />
          </div>
        </div>
        <div
          className="playlist-videos-con"
          style={{ height: heightPlr, overflowY: "auto" }}
        >
          {currentPlaylist?.playlist?.videos?.length > 0 ? (
            <div style={{ width: "80%", margin: "0 auto" }}>
              {currentPlaylist?.playlist?.videos?.map((record) => {
                  if (record.id === currentPlayingVideo?.id) {
                    return (
                      <>
                        <div className='rowSelected'
                          style={{ width: "100%", padding: "1px" }}>
                          <img src={record.thumbnail_url} alt='' width='100%' />
                          <h5 className="font-semibold"
                          >{`${record.title} (Playing)`}</h5>
                          <h6>{record.artist}</h6>
                        </div>
                        <br />
                      </>
                    )
                  } else {
                    return (
                      <div style={{ color: "#ff71ee" }}>
                        <img src={record.thumbnail_url} alt={record.title} width='100%'
                          style={{ cursor: "pointer" }}
                          onClick={() => handleChangeVideo(record.id)} />
                        <h5 className="font-semibold"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleChangeVideo(record.id)}
                        >{record.title}</h5>
                        <h6>{record.artist}</h6>
                        <br />
                      </div>
                    )
                  }
                })}
            </div>
          ) : (
            <Spinner color="warning">Loading....</Spinner>
          )}
        </div>
      </div>

      {/* <div className="img-con">
        <a href="https://app.gogopix.co/gogopixtv">
          <img
            className="imgBtn"
            style={{ marginTop: "45px" }}
            src={stoIcns}
            width="30%"
            alt="get the app logo"
          />
        </a>
      </div> */}
      <footer
        style={{
          textAlign: "center",
          color: "#fff",
          backgroundColor: "#000",
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
        }}
      >
        <a href="https://prod-vibestream.sfo3.digitaloceanspaces.com/VibestreambyGOGOPIXInc-termsandconditions.pdf">
          Terms
        </a>{" "}
        |{"  "}
        <a href="https://prod-vibestream.sfo3.digitaloceanspaces.com/VibestreambyGOGOPIXInc-PrivacyPolicy.pdf">
          Privacy Policy
        </a>{" "}
        |{"  "}
        <Link to="/contactus">Contact Us</Link>
      </footer>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Video URL copied</ModalHeader>
        <ModalBody>
          The video url titled "{copiedTitle}" has been copied to the clipboard.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showMaxSkipWarning}
        toggle={() => setShowMaxSkipWarning(false)}
      >
        <ModalHeader>Skip limit exceeded</ModalHeader>
        <ModalBody>You ran out of skips. Please enjoy this video.</ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setShowMaxSkipWarning(false)}
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default App;
