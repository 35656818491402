import dayjs from "dayjs";

function copyToClipboard(textToCopy) {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(textToCopy);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;

    // Move textarea out of the viewport so it's not visible
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
}

const MILLISECONDS_IN_TWENTY_MINUTES = 20 * 60 * 1000;
function logSkipVideo(videoId, maxSkips = 3) {
  const firstSkipAt = Number(localStorage.getItem("firstSkipAt"));
  const skippedVideos = localStorage.getItem("skipVideos");
  const parsedSkippedVideos = JSON.parse(skippedVideos || "[]");

  if (
    !firstSkipAt ||
    dayjs.utc().diff(dayjs(firstSkipAt), "milliseconds") >
      MILLISECONDS_IN_TWENTY_MINUTES
  ) {
    localStorage.setItem("firstSkipAt", new Date().getTime());
    localStorage.setItem("skipVideos", JSON.stringify([videoId]));
    return true;
  } else if (parsedSkippedVideos.length < maxSkips) {
    parsedSkippedVideos.push(videoId);
    localStorage.setItem("skipVideos", JSON.stringify(parsedSkippedVideos));
    return true;
  }
  return false;
}

export { copyToClipboard, logSkipVideo };
