import { useCallback, useEffect, useRef } from "react";
import { recordVideoAnalytics } from "../service/featured";

function useVideoAnalytics() {
  const videoIdRef = useRef(null);

  const watchedDuration = useRef(0);

  const durationRecorder = useRef();

  const startRecordingDuration = useCallback((newVideoId) => {
    if (videoIdRef.current !== newVideoId) {
      if (videoIdRef.current) {
        recordVideoAnalytics({
          id: videoIdRef.current,
          device: "web",
          duration: watchedDuration.current,
        });
      }
      watchedDuration.current = 0;
      videoIdRef.current = newVideoId;
    }
    pauseRecordingDuration();
    resumeRecordingDuration();
  }, []);

  const pauseRecordingDuration = useCallback(() => {
    clearInterval(durationRecorder.current);
  }, []);

  const resumeRecordingDuration = useCallback(() => {
    durationRecorder.current = setInterval(() => {
      watchedDuration.current += 1;
    }, 1000);
  }, []);

  const recordRecordingDuration = useCallback(() => {
    if (videoIdRef.current) {
      recordVideoAnalytics({
        id: videoIdRef.current,
        device: "web",
        duration: watchedDuration.current,
      });

      watchedDuration.current = 0;
      videoIdRef.current = null;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", recordRecordingDuration);
    return () => {
      window.removeEventListener("beforeunload", recordRecordingDuration);
    };
  }, []);

  useEffect(() => {
    return () => {
      recordRecordingDuration();
    };
  }, []);

  return {
    videoId: videoIdRef.current,
    start: startRecordingDuration,
    pause: pauseRecordingDuration,
    resume: resumeRecordingDuration,
    record: recordRecordingDuration,
  };
}

export default useVideoAnalytics;
