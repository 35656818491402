import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import SingleVideo from './SingleVideo';
import Playlist from './Playlist';
import ViewPlaylists from './ViewPlaylists';
import ContactUs from './ContactUs';
import EmbedVideo from './EmbedVideo';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';

dayjs.extend(utc);

ReactDOM.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route exact path="/video/*" element={<SingleVideo />} />
      <Route exact path="/allplaylists" element={<ViewPlaylists />} />
      <Route exact path="/playlist/*" element={<Playlist />} />
      <Route exact path="/contactus" element={<ContactUs />} />
      <Route exact path='/embed/:videoId' element={<EmbedVideo />} />
    </Routes>
  </Router>,
  document.getElementById("root")
);