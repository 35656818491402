import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getSingleVideo } from "./service/featured";
import videojs from "video.js";
import "videojs-playlist";
import "videojs-contrib-ads";
import "videojs-ima";
import "video.js/dist/video-js.css";
import "videojs-ima/dist/videojs.ima.css";
import "bootstrap/dist/css/bootstrap.css";
import vibeStreamLogo from "./assets/Vibe-Stream-Logo.png";
import codeIcon from "./assets/code-icon.png";
import { copyToClipboard } from "./helpers/globals";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export function CopyEmbedVideo({ video }) {
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  function handleCopy(width, height) {
    return () => {
      setIsCopyModalOpen(false);
      copyToClipboard(
        `<iframe width="${width}" height="${height}" src="${window.location.protocol}//${window.location.host}/embed/${video?.video_id}"></iframe>`
      );
      setCopied(true);
    };
  }

  return (
    <>
      <img
        src={codeIcon}
        alt="code icon"
        style={{ cursor: "pointer" }}
        onClick={() => setIsCopyModalOpen(true)}
      />
      <Modal
        isOpen={isCopyModalOpen}
        toggle={() => setIsCopyModalOpen(!isCopyModalOpen)}
      >
        <ModalHeader>Embed Video</ModalHeader>
        <ModalBody style={{display: 'flex', justifyContent: 'center', gap: '1rem'}}>
          <Button color="primary" onClick={handleCopy(300, 600)}>
            Copy (300x600)
          </Button>
          <Button color="primary" onClick={handleCopy(970, 250)}>Copy (970x250)</Button>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setIsCopyModalOpen(!isCopyModalOpen)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={copied} toggle={() => setCopied(!copied)}>
        <ModalHeader>Embed video copied</ModalHeader>
        <ModalBody>
          The video embed code of "{video?.title}" has been copied to the
          clipboard.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setCopied(!copied)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

function EmbedVideo() {
  const [video, setVideo] = useState(null);

  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const params = useParams();

  useEffect(() => {
    const googletag = window.googletag;
    googletag.cmd.push(function () {
      googletag.display("div-gpt-ad-1699300221224-0");
    });

    const imaOptions = {
      adTagUrl:
        "https://pubads.g.doubleclick.net/gampad/ads?iu=/22978710279/halfpage-for-video&description_url=[placeholder]&tfcd=0&npa=0&sz=640x480&ciu_szs=300x600&gdfp_req=1&output=vast&env=vp&unviewed_position_start=1&impl=s&correlator=",
    };

    getSingleVideo(params.videoId)
      .then((response) => {
        const fetchedVideo = response.data.data;
        setVideo(fetchedVideo);

        const videoMeta = {
          src: fetchedVideo.video_url,
          type: "application/x-mpegURL",
        };

        const videoOptions = {
          controls: true,
          controlBar: {
            pictureInPictureToggle: false,
          },
        };

        playerRef.current = videojs(videoRef.current, videoOptions);
        playerRef.current.src(videoMeta);
        playerRef.current.ima(imaOptions);

        return () => {
          playerRef.current.dispose();
        };
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="embed-container">
      {video && (
        <>
          <div className="logo-image-sm">
            <img src={vibeStreamLogo} alt="logo" />
          </div>
          <div className="embed-player">
            <video
              ref={videoRef}
              className="video-js vjs-fluid vjs-default-skin vjs-big-play-centered single-video"
              width="auto"
              height="auto"
              preload="auto"
              allowfullscreen
              loop
              poster={video?.thumbnail_url}
            />
          </div>
          <div className="embed-footer">
            <div className="embed-info">
              <img src={vibeStreamLogo} alt="logo" className="logo-image" />
              <img
                src={video?.thumbnail_url}
                alt="thumbnail"
                className="embed-campaign-image"
              />
              <span>
                {video?.title} by {video?.artist}
              </span>
            </div>
            <div className="embed-playlist">
              <img src={video?.album_artwork} alt={video?.title} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default EmbedVideo;
